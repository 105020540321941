<template>
  <div class="new">
    <div class="container">
      <div class="title">新闻动态</div>
      <ul class="list-unstyled">
        <li
          v-for="(item, idIndex) in newsArr"
          :key="idIndex"
          @click="toDetali(idIndex)"
        >
          <!-- <router-link to="/new/detail?id=idIndex"> -->
          <div class="news">
            <div class="newstext">
              <h5>{{ item.title }}</h5>
              <span>{{ item.date }}</span>
              <p class="contentTit" v-html="item.text"></p>
            </div>
            <div
              class="imgs"
              :style="{ backgroundImage: `url(${item.newsPic})` }"
            ></div>
          </div>
          <!-- </router-link> -->
        </li>
      </ul>
      <!-- <a href="" class="viewmore">
        <p style="margin-bottom: 0">查看更多</p>
        <img :src="arrow" />
        <span>TO VIEW MORE</span>
      </a> -->
    </div>
  </div>
</template>

<script>
import arrow from "@/assets/arrow4.png";
import newsLists from "../../assets/json/news";
export default {
  data() {
    return {
      arrow: arrow,
      newsArr: newsLists.newsLists,
    };
  },
  created() {
    console.log(this.newsArr);
  },
  methods: {
    toDetali(index) {
      this.$router.push(`/new/detail?id=${index}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  color: black;
}
a:hover {
  text-decoration: none;
}
.container {
  text-align: left;
}

.title {
  margin-top: 200px;
  font-weight: 500;
  font-size: 30px;
  margin-left: unset;
  color: #333333;
  span {
    font-size: 13px;
    font-weight: unset;
  }
}
.container {
  .title {
    position: relative;
    color: #333333;
    font-size: 30px;
    font-weight: 500;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -20px;
      display: block;
      width: 5.5rem;
      height: 1px;
      border-radius: 3px;
      background-color: #3c8cff;
      border: 2px solid #3c8cff;
    }
  }
}
.container {
  padding-right: 0px;
  cursor: pointer;
  padding-left: 0px;
}
.news {
  display: flex;
  justify-content: space-between;
  opacity: 1;
  background: #ffffff;
  box-shadow: 10px 20px 60px 0px rgba(194, 240, 255, 0.29);
  margin-top: 108px;
  padding-bottom: 50px;
  .newstext {
    .contentTit {
      font-size: 18px;
      color: #666;
      height: 54px;
      margin-top: 100px;
      justify-self: 54px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.news {
  .imgs {
    width: 26.8%;
    margin: 40px 50px 0 0;
    background-size: cover;
    background-position: center;
  }
}

.newstext {
  width: 60%;
  margin: 40px 0 0 40px;
}

.newstext h5 {
  font-size: 22px;
  font-weight: 500;
  color: #333333;
}

.newstext span {
  display: block;
  font-size: 16px;
  color: #999;
  margin-top: 20px;
  font-weight: 400;
}

.viewmore {
  float: left;
  text-align: center;
  margin: 138px 0 100px;
}
.viewmore p {
  font-size: 20px;
  color: #4899ef;
}
.viewmore span {
  display: block;
  font-size: 12px;
  color: #4899ef;
}
@media (max-width: 768px) {
  .container {
    padding: 0 15px;
    margin-top: 80px;
    .title {
      position: relative;
      font-size: 20px;
      color: #333333;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 0.8rem;
        height: 0px;
        border: 1px solid #4899ef;
      }
    }
  }
  .title {
    margin-top: 2rem;
    font-size: 1rem;
  }
  .container {
    height: unset;
  }
  .container > span {
    float: left;
    width: 20px;
    border-radius: 3px;
    margin: unset;
  }
  .viewmore {
    display: none;
  }
  .news {
    padding: 15px 8px 0px 8px;
    display: flex;
    box-shadow: 2px 2px 10px #76cff547;
    margin: 15px 0px;
    .newstext {
      width: 60%;
      margin: 0px 0 0 0px;
      h5 {
        font-size: 14px;
        font-weight: 700;
        color: #333333;
        width: 100%;
        height: 1rem;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: justify;
      }
      span {
        font-size: 12px;
        color: #999999;
        margin-top: 0px;
      }
      .contentTit {
        margin-top: 15px;
        margin-bottom: 20px;
        height: 34px;
        font-size: 12px;
        color: #666666;
      }
    }

    .imgs {
      width: 35%;
      background-size: cover;
      margin: 20px 0px;
    }
  }
}
</style>